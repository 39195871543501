import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../../components/seo/SEO';
import styles from './ServiceDetailed.module.scss';
import Layout from '../../components/layout/Layout';

export const query = graphql`
  query {
    smoke: file(relativePath: { eq: "incident/smoke.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const IncidentPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <SEO
        title={
          'Incident investigation and support. Read about which services we can provide you with in this field.'
        }
      />
      <Layout>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong pb-48 mb-48">
          <div>
            <h1 className={styles.welcomeText}>Incident investigation and support</h1>
            <p className={styles.descriptionText}>
              When an incident or accident happens, it is very import to understand the causes and
              the course of the incident. There is a need to understand what went wrong, whether the
              incident could have developed into an even worse accident, and to find ways to prevent
              similar incidents from happening in the future. For such work, the combination of
              solid phenomenon understanding and accurate CFD-calculations that illustrate the
              physics is a key.
            </p>
            <p className={styles.descriptionText}>
              Olav Roald Hansen has supported incident and accident investigations performing
              detailed CFD calculations on several occasions. For some of these articles are
              published, see below, while others are client confidential.
            </p>
            <p className={styles.descriptionText}>
              In 2019, an attempt to model, understand and explain the consequences of the Kjørbo
              hydrogen refuelling station explosion was performed as part of the MoZEES project.
              Results were presented at an open project workshop October 23, 2019, using publicly
              available information only. This study concluded that the main explosion must have
              been an unconfined detonation of the gas cloud released from the high-pressure storage
              bottle. The slides from the presentation can be found{' '}
              <a
                href="https://mozees.no/wp-content/uploads/2019/10/Hansen_Hydrogen-safety_Kjoerbo-incident-overview-and-perspectives.pdf"
                title="Hydrogen Safety"
              >
                here.
              </a>
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.smoke.childImageSharp.fluid} alt="" />
            </figure>
          </div>
        </div>
        <div className={`grid_vertical_padding ${styles.articleTitle}`}>Published works:</div>
        <div className={`grid_vertical_padding ${styles.articleContainer}`}>
          <div className={styles.articleColumn}>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/267693904_2005_Buncefield_vapour_cloud_explosion_Unraveling_the_mystery_of_the_blast
                "
                target="_blank"
                rel="noopener noreferrer"
                title="Buncefield explosion
                "
              >
                Davis, S. G., Hinze, P., Hansen, O. R., van Wingerden, K. (2010). 
                2005 Buncefield vapour cloud explosion: Unraveling the mystery of the blast. 
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://aiche.onlinelibrary.wiley.com/doi/abs/10.1002/prs.10408
                "
                target="_blank"
                rel="noopener noreferrer"
                title="Explosion in dryer"
              >
                Davis, S. G., Engel, D., Hansen, O. R. (2010). 
                Case study summary of dryer explosion and venting design.
              </a>
            </div>
          </div>
          <div className={styles.articleColumn}>
          <div className={styles.article}>
              <a
                href="https://www.sciencedirect.com/science/article/abs/pii/S0950423009001806?via%3Dihub"
                target="_blank"
                rel="noopener noreferrer"
                title="Danvers explosion"
              >
                Davis, S. G., Hansen, O. R., (2010). New investigation findings on the 2006 Danvers, MA explosion.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.sciencedirect.com/science/article/abs/pii/S1352231008008509?via%3Dihub
                "
                target="_blank"
                rel="noopener noreferrer"
                title="Article including simulations of Festus, Missouri, chlorine release"
              >
                Hanna, S. R., Hansen, O. R., Ichard, M., Strimaitis, D. (2009). 
                CFD model simulation of dispersion from chlorine railcar releases in industrial and urban areas.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/profile/Olav_Hansen/publications
                "
                target="_blank"
                rel="noopener noreferrer"
                title="ResearchGate"
              >
                More at ResearchGate
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IncidentPage;
